import { getMessage, getCenterCTScanProUrl } from "../../utils";
import axios from "axios";

export function getAssumeRole(params,token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/common/getAssumeRole`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

export function getEmailMsg(params, token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/msg/design/get`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

export function updateEmailMsg(params, token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/msg/design/update`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}

export function loadElementOptions(params, token) {
  let baseUrl = getCenterCTScanProUrl();
  let data = getMessage(params, true, true);
  return axios.post(`${baseUrl}/msg/element/elementOptions`, data, {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json'
    }
  });
}
