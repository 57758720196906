
import { Base64 } from 'js-base64';
import system from "./system";

/* eslint-disable no-undef */
function getMessage(obj, enc, paramIn) {
  var msg = JSON.stringify(obj);
  var req = {
    key: "",
    data: Base64.encode(msg)
  };
  if (paramIn === true) {
    var paramIn = {
      paramIn: Base64.encode(msg)
    };
    req = {
      key: "",
      data: Base64.encode(JSON.stringify(paramIn))
    };
    return req
  }
  return {}
}

function getCenterCTScanProUrl() {
  if (system.debug) {
    return "https://ctscan0.armloop.com.au/CTScan/pro";
  } else {
    return "https://ctscan2.armloop.com.au/CTScan/pro";
  }
}

export {
  getMessage,
  getCenterCTScanProUrl
};
