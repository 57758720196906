
import { Empty } from "antd";
import React from "react";
class NotFound extends React.Component {
  render() {
    const styles = {
      width: '100%',
      height: '100%',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return (
      <div style={styles}>
        <Empty description={'404 Not Found'} />
     </div>
    )
  }
}


export default NotFound;
