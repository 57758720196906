
import { Uploader } from "../utils/Uploader";
import { MjmlToJson } from 'easy-email-extensions';


const uploader = new Uploader(async () => (''), {
  autoUpload: false,
  limit: 1,
  accept: '*'
})


export function useImportTemplate() {

  const importTemplate = async () => {
    const [file] = await uploader.chooseFile();
    const reader = new FileReader();
    return new Promise ((resolve, reject) => {
      reader.onload = function (evt) {
        if (!evt.target) {
          reject();
          return;
        }
        try {
          const pageData = MjmlToJson(evt.target.result);
          resolve([file.name, pageData]);
        } catch (error) {
          reject()
        }
      };
      reader.readAsText(file);
    })

  }

  return { importTemplate }
}
