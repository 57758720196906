import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom'
import './App.css';
import Eamil from './pages/email';
import NotFound from './pages/notFound';
import Email from './pages/email';



// 1. 准备一个路由数组 数组中定义所有的路由对应关系
const routesList = [
  {
    key: "002",
    path: '/email',
    element: Eamil,
  },
  // 增加n个路由对应关系
  {
    key:"001",
    path: '*',
    element: NotFound,
  },
]

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {routesList.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              component={route.element}
            />
          ))}
        </Switch>
      </Router>
    </div>
  )
}

export default App;
