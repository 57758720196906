import { uniqueId } from 'lodash';

export class Uploader {
   options;
   el;
   uploadServer;
   handler = {
    start: [],
    progress: [],
    end: [],
  };

  constructor(uploadServer ,options) {
    this.options = {
      limit: 1,
      autoUpload: true,
      ...options,
    };
    this.uploadServer = uploadServer;
    this.el = this.createInput();
  }

   createInput() {
    Array.from(document.querySelectorAll('.uploader-form-input')).forEach(
      (el) => {
        el && document.body.removeChild(el);
      }
    );
    const el = document.createElement('input');
    el.className = 'uploader-form-input';
    el.type = 'file';
    el.style.display = 'block';
    el.style.opacity = '0';
    el.style.width = '0';
    el.style.height = '0';
    el.style.position = 'absolute';
    el.style.top = '0';
    el.style.left = '0';
    el.style.overflow = 'hidden';
    el.multiple = this.options.limit > 1;
    if (this.options.accept) {
      el.accept = this.options.accept;
    }
    return el;
  }

   async uploadFiles(files) {
    const results = files.map((file) => ({ file }));
    const uploadList = results.map((item) => ({
      url: '',
      status: 'pending',
      idx: `uploader-${uniqueId()}`,
    }));

    // 开始上传
    this.handler.start.map((fn) => fn(uploadList));

    await PromiseEach(
      results.map(async (file, index) => {
        try {
          const url = await this.uploadFile(file);
          uploadList[index].url = url;
          uploadList[index].status = 'done';
        } catch (error) {
          uploadList[index].status = 'error';
        } finally {
          this.handler.progress.map((fn) => fn(uploadList));
        }
      })
    );

    // 上传完成
    this.handler.end.map((fn) => fn(uploadList));
  }

   async uploadFile(result) {
    return this.uploadServer(result.file);
  }

   checkFile(files) {
    const typeError = this.checkTypes(files);
    if (typeError) {
      throw new Error(typeError);
    }

    const sizeError = this.checkSize(files);
    if (sizeError) {
      throw new Error(sizeError);
    }
  }

   checkTypes(files) {
    const accept = this.options.accept;
    if (accept) {
      let fileType = '';
      if (accept.indexOf('image') !== -1) {
        fileType = 'image';
      } else if (accept.indexOf('video') !== -1) {
        fileType = 'video';
      }
      for (const file of files) {
        if (file.type.indexOf(fileType) !== 0) {
          return 'Error Type!';
        }
      }
    }
    return null;
  }

   checkSize(files) {
    const options = this.options;
    for (const file of files) {
      if (options.minSize && file.size < options.minSize) {
        return `上传文件不能小于 ${options.minSize}`;
      }
      if (options.maxSize && file.size > options.maxSize) {
        return `上传文件不能小于 ${options.maxSize}`;
      }
    }
    return null;
  }

   chooseFile() {
    const el = this.el;
    document.body.appendChild(el);
    el.click();

    return new Promise((resolve) => {
      el.onchange = async (e) => {
        let files = e.target.files || [];
        files = Array.prototype.slice.call(files);
        if (files.length === 0) {
          return;
        }
        this.checkFile(files);
        if (this.options.autoUpload) {
          this.uploadFiles(files);
        } else {
          resolve(files)
        }
        el.onchange = null;
        el.parentNode && el.parentNode.removeChild(el);
      };
    })
  }

   on(
    event,
    fn
  ) {
    // UploaderEventMapHandle[K] === UploaderEventMap[K][]
    const handler = this.handler[event];
    handler.push(fn);
  }

   off(
    event,
    fn
  ) {
    const handles = this.handler[event];
    this.handler[event] = handles.filter(
      (item) => item !== fn
    );
  }
}

export function PromiseEach(promiseLikes) {
  const datas = [];
  let count = 0;
  return new Promise((resolve) => {
    promiseLikes.forEach(async (promiseLike) => {
      try {
        const data = await promiseLike;
        datas.push(data);
      } catch (error) {
        datas.push(error);
      }
      finally {
        count++;
        if (count === promiseLikes.length) {
          resolve(true);
        }
      }
    });
  });
}

